import Image from 'next/legacy/image'
import cn from '#/utils/classnames'
import styles from '../styles.module.scss'

export default function HomeHero({ requestDemo }: {
  requestDemo: (product: TopHap.UIState['feedback']['product']) => void
}) {
  const imgBaseUrl = '/images/landing'

  return (
    <section className={cn(styles['th-section'], styles['th-hero-section'])}>
      <div className={cn(styles['th-section-content'], 'relative flex flex-wrap items-center')}>
        <Image
          alt='TopHap Real Estate'
          // srcSet={`${imgBaseUrl}/tophap-map-750.jpg 750w, ${imgBaseUrl}/tophap-map-1080.jpg 1080w, ${imgBaseUrl}/tophap-map-1440.jpg 1440w, ${imgBaseUrl}/tophap-map-1920.jpg 1920w, ${imgBaseUrl}/tophap-map.jpg 2792w`}
          className={styles['th-section-background']}
          layout='fill'
          priority
          src={`${imgBaseUrl}/tophap-map.jpg`}
        />
        <div className='z-10 mx-auto flex w-full flex-col items-center justify-center p-10 text-center lg:w-3/4 lg:p-20'>
          <h1 className='font-heading tracking-px-n mb-7 text-4xl font-bold text-white md:text-4xl lg:leading-none xl:text-6xl'>
            Real Estate{' '}
            <hr className='hide h-2 bg-transparent lg:block' />
            <span className='text-[#57ee47]'>Intelligence</span> Platform
          </h1>
          <p className='mb-10 text-lg font-thin text-white lg:w-1/2 lg:text-2xl'>
            Transform your business with TopHap's enriched real estate data and location-based tools
          </p>
          <div className='mb-6 md:inline-block'>
            <button className='rounded-full border border-primary bg-primary px-8 py-4 text-white transition duration-200 ease-in-out hover:bg-indigo-700 focus:ring focus:ring-indigo-300' onClick={() => requestDemo('TopHap')}>Request Demo</button>
          </div>
        </div>
      </div>
    </section>
  )
}
