const sitemap = [
  { title: 'Alabama', href: '/sitemap/usa/al' },
  { title: 'Alaska', href: '/sitemap/usa/ak' },
  { title: 'Arizona', href: '/sitemap/usa/az' },
  { title: 'Arkansas', href: '/sitemap/usa/ar' },
  { title: 'California', href: '/sitemap/usa/ca' },
  { title: 'Colorado', href: '/sitemap/usa/co' },
  { title: 'Connecticut', href: '/sitemap/usa/ct' },
  { title: 'Delaware', href: '/sitemap/usa/de' },
  { title: 'District of Columbia', href: '/sitemap/usa/dc' },
  { title: 'Florida', href: '/sitemap/usa/fl' },
  { title: 'Georgia', href: '/sitemap/usa/ga' },
  { title: 'Hawaii', href: '/sitemap/usa/hi' },
  { title: 'Idaho', href: '/sitemap/usa/id' },
  { title: 'Illinois', href: '/sitemap/usa/il' },
  { title: 'Indiana', href: '/sitemap/usa/in' },
  { title: 'Iowa', href: '/sitemap/usa/ia' },
  { title: 'Kansas', href: '/sitemap/usa/ks' },
  { title: 'Kentucky', href: '/sitemap/usa/ky' },
  { title: 'Louisiana', href: '/sitemap/usa/la' },
  { title: 'Maine', href: '/sitemap/usa/me' },
  { title: 'Maryland', href: '/sitemap/usa/md' },
  { title: 'Massachusetts', href: '/sitemap/usa/ma' },
  { title: 'Michigan', href: '/sitemap/usa/mi' },
  { title: 'Minnesota', href: '/sitemap/usa/mn' },
  { title: 'Mississippi', href: '/sitemap/usa/ms' },
  { title: 'Missouri', href: '/sitemap/usa/mo' },
  { title: 'Montana', href: '/sitemap/usa/mt' },
  { title: 'Nebraska', href: '/sitemap/usa/ne' },
  { title: 'Nevada', href: '/sitemap/usa/nv' },
  { title: 'New Hampshire', href: '/sitemap/usa/nh' },
  { title: 'New Jersey', href: '/sitemap/usa/nj' },
  { title: 'New Mexico', href: '/sitemap/usa/nm' },
  { title: 'New York', href: '/sitemap/usa/ny' },
  { title: 'North Carolina', href: '/sitemap/usa/nc' },
  { title: 'North Dakota', href: '/sitemap/usa/nd' },
  { title: 'Ohio', href: '/sitemap/usa/oh' },
  { title: 'Oklahoma', href: '/sitemap/usa/ok' },
  { title: 'Oregon', href: '/sitemap/usa/or' },
  { title: 'Pennsylvania', href: '/sitemap/usa/pa' },
  { title: 'Rhode Island', href: '/sitemap/usa/ri' },
  { title: 'South Carolina', href: '/sitemap/usa/sc' },
  { title: 'South Dakota', href: '/sitemap/usa/sd' },
  { title: 'Tennessee', href: '/sitemap/usa/tn' },
  { title: 'Texas', href: '/sitemap/usa/tx' },
  { title: 'Utah', href: '/sitemap/usa/ut' },
  { title: 'Vermont', href: '/sitemap/usa/vt' },
  { title: 'Virginia', href: '/sitemap/usa/va' },
  { title: 'Washington', href: '/sitemap/usa/wa' },
  { title: 'West Virginia', href: '/sitemap/usa/wv' },
  { title: 'Wisconsin', href: '/sitemap/usa/wi' },
  { title: 'Wyoming', href: '/sitemap/usa/wy' }
]

export default sitemap
