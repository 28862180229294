import SvgCensus from '#/assets/images/icons/categories/census.svg'
import SvgInvestment from '#/assets/images/icons/categories/investment.svg'
import SvgMarket from '#/assets/images/icons/categories/market.svg'
import SvgPrice from '#/assets/images/icons/categories/price.svg'
import SvgProperty from '#/assets/images/icons/categories/property.svg'
import SvgRegion from '#/assets/images/icons/categories/region.svg'
// import SvgSchool from '#/assets/images/icons/categories/school.svg'
import SvgWarning from '#/assets/images/icons/categories/warning.svg'

export type AnalyticsCategory = {
  Icon: React.ComponentClass<{ className?: string }>
  id: string
  color: string
  title: string
  items: TopHap.AnalyticsMetric[]
}

const categories: AnalyticsCategory[] = [
  {
    id: 'value',
    color: '#6651F5',
    title: 'Value',
    Icon: SvgPrice,
    items: [
      'estimate_price',
      'estimate_ppsf'
      // 'estimate_change',
      // 'estimate_rent_price',
      // 'estimate_rent_ppsf',
      // 'estimate_accuracy',
      // 'estimate_rent_accuracy'
    ]
  },
  {
    id: 'property',
    color: '#f39c0b',
    title: 'Property',
    Icon: SvgProperty,
    items: [
      'living_area',
      // 'bedrooms',
      // 'bathrooms',
      'lot_size_acres',
      'slope',
      // 'flattest_area_acres',
      // 'flattest_parcel_ratio',
      // 'usable_area',
      // 'garage',
      // 'pool',
      'stories',
      'age'
      // 'property_use',
      // 'hoa_fee'
    ]
  },
  {
    id: 'region',
    color: '#e2d000',
    title: 'Region',
    Icon: SvgRegion,
    items: [
      'walkability',
      'noise',
      // 'census_travel_time_to_work',
      // 'census_population_daytime',
      // 'census_population_seasonal',
      'elevation',
      'precipitation'
      // 'temperature',
      // 'crime',
      // 'zoned_code',
      // 'unique_zones',
      // 'count' /* ,
      // 'diffusion' */
    ]
  },
  {
    id: 'hazards',
    color: '#E30000',
    title: 'Hazards',
    Icon: SvgWarning,
    items: [
      // 'hazard_earthquake',
      'hazard_weather',
      // 'hazard_wind',
      // 'hazard_ozone',
      'hazard_pollution',
      // 'hazard_co2',
      // 'hazard_no2',
      // 'hazard_lead',
      // 'hazard_particules',
      // 'hazard_hail',
      // 'hazard_hurricane',
      // 'hazard_tornado',
      // 'climate_drought_risk',
      // 'climate_fema_flood_risk',
      // 'climate_flood_risk',
      // 'climate_heat_risk',
      // 'climate_storm_risk',
      // 'climate_total_risk',
      // 'climate_wildfire_risk',
      // 'nri_national',
      // 'nri_avalanche',
      // 'nri_coastal_flooding',
      // 'nri_cold_wave',
      'nri_drought',
      'nri_earthquake'
      // 'nri_hail',
      // 'nri_heat_wave',
      // 'nri_hurricane',
      // 'nri_ice_storm',
      // 'nri_land_slide',
      // 'nri_lightning',
      // 'nri_riverine_flooding',
      // 'nri_strong_wind',
      // 'nri_tornado',
      // 'nri_tsunami',
      // 'nri_volcano',
      // 'nri_wildfire',
      // 'nri_winter_weather'
    ]
  },
  {
    id: 'market',
    color: '#31BCDA',
    title: 'Market',
    Icon: SvgMarket,
    items: [
      // 'list_vs_sold',
      // 'dom',
      // 'ownership_days',
      // 'owner_occupied',
      'sale_price',
      'price_per_sqft'
      // 'turnover'
    ]
  },
  {
    id: 'investment',
    color: '#E158EE',
    title: 'Investment',
    Icon: SvgInvestment,
    items: [
      // 'rent_yield',
      'estimate_sold_price_ratio',
      'tax',
      'tax_rate'
      // 'profit',
      // 'permits_count',
      // 'permits_value'
    ]
  },
  {
    id: 'community',
    color: '#9D58FF',
    title: 'Community',
    Icon: SvgCensus,
    items: [
      // 'unemployment_ratio',
      // 'unemployment',
      // 'census_population',
      // 'census_population_density',
      // 'census_population_female',
      // 'census_population_male',
      // 'census_age_median',
      // 'census_marriage_never',
      // 'census_marriage_now',
      // 'census_divorced',
      // 'census_widowed',
      // 'census_separated',
      // 'census_collar_blue',
      // 'census_collar_white',
      'census_educational_climate_index',
      // 'census_employee_salary_average',
      // 'census_employee_salary_median',
      // 'census_per_capita_income',
      // 'census_households',
      // 'census_family_size',
      // 'census_household_size',
      'census_household_income_median',
      // 'census_household_disposable_income_median',
      'census_household_total_expenditure_average'
      /* 'census_household_income_average', */
      /* 'census_household_total_expenditure', */
    ]
  }
  // {
  //   id: 'school',
  //   color: '#A0D276',
  //   title: 'School',
  //   Icon: SvgSchool,
  //   items: [
  //     'school_enrollment',
  //     'school_school_rating',
  //     'school_student_teacher_ratio'
  //   ]
  // }
]

export default categories
