import get from 'lodash-es/get'
import { connect } from 'react-redux'
import { ShowFeedback } from '#/store/actions/ui'
import Home from './Home'

const mapStateToProps = (state: TopHap.StoreState) => ({
  authenticated: state.user.info.isAnonymous === false,
  currentRole: get(state.user, 'paymentInfo.role'),
  isApple: state.global.isApple,
  isMobile: state.global.isMobile
})

const mapDispatchToProps = { showFeedback: ShowFeedback }

export default connect(mapStateToProps, mapDispatchToProps)(Home)
