import { NextPage } from 'next'
import dynamic from 'next/dynamic'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useStore } from 'react-redux'
import SvgData from '#/assets/images/icons/categories/database.svg'
import SvgMap from '#/assets/images/icons/map.svg'
import SvgTrends from '#/assets/images/icons/trends.svg'
import Link /* , { LinkButton } */ from '#/components/Link'
import Tooltip from '#/components/Tooltip'
import { BREAK_SM } from '#/consts'
import { analyticsData } from '#/consts/analytics'
import categories from '#/containers/MapView/components/AnalyticsLayerPanel/categories'
import { logEvent } from '#/services/analytics'
import { initialState } from '#/store/reducers/preferences'
import cn from '#/utils/classnames'
import { setIn } from '#/utils/object'
import { state2MapUrl } from '#/utils/url'
import imgInmanLogo from '@public/images/landing/logos/inman-logo.png'
import SVGAppStoreButton from '../../../public/images/landing/app-store-button.svg'
import HomeHero from './HomeHero'
import sitemapLinks from './sitemap'
import styles from './styles.module.scss'
import { Props } from './types'

const CookieConsent = dynamic(() => import('#/components/CookieConsent'), { ssr: false })

const imgBaseUrl = '/images/landing'

const Home: NextPage<Props> = ({ showFeedback }) => {
  const [allLinks, showAllLinks] = useState(true)
  const router = useRouter()
  const store = useStore<TopHap.StoreState>()

  function onLearnMore(product: TopHap.UIState['feedback']['product']) {
    showFeedback({
      visible: true,
      product
    })

    logEvent('select_content', {
      content_type: 'landing',
      item_id: `product/${product}`
    })
  }

  function toggleAllLinks() {
    showAllLinks(!allLinks)
  }

  function onAnalyticsMetric(metric: TopHap.AnalyticsMetric) {
    const storeState = store.getState()
    let newStore = setIn(storeState, 'preferences.map.descriptive', {
      ...initialState.map.descriptive,
      enabled: true,
      metric
    })
    newStore = setIn(storeState, 'preferences.map.properties.enabled', false)

    if (storeState.ui.viewport.width <= BREAK_SM) {
      newStore = setIn(newStore, 'ui.sider.visible', false)
    }

    router.push(state2MapUrl(newStore))
  }

  type Block = {
    imgAlt: string;
    imgName: string;
    imgWidth: number;
    imgHeight: number;
    title: React.ReactNode;
    description: string;
    subDescription?: string[];
    action?: React.ReactNode;
    tagLineClass?: string;
  }

  function renderFeatureBlock({
    imgAlt,
    imgName,
    title,
    description,
    subDescription,
    action,
    tagLineClass
  }: Omit<Block, 'imgWidth' | 'imgHeight'>) {
    return (
      <div className={cn(styles['th-feature-block'], 'bg-[#f2f2f2] rounded-4xl flex flex-col')}>
        <div className='rounded-4xl flex h-full flex-col px-[40px] pt-[30px]'>
          <h3 className='font-heading mb-2 text-2xl font-thin'>{title}</h3>
          <p className={cn('mb-4 text-lg font-thin', tagLineClass)}>{description}</p>
          <div className='flex h-full flex-col justify-between'>
            <ul className='mb-6 list-disc font-thin leading-relaxed text-stone-600'>{
              subDescription?.map(item => (
                <li key={title + item.slice(5)}>{item}</li>
              ))
            }
            </ul>
            <div className='flex items-end justify-self-end'>
              {action}
            </div>
          </div>
        </div>
        <div className={cn(styles['th-block-img'], 'rounded-xl bg-[#f7f7f7]')}>
          <Image
            alt={imgAlt}
            className='rounded-xl'
            height={512}
            layout='responsive'
            src={`${imgBaseUrl}/${imgName}.png`}
            width={724}
          />
        </div>
      </div>
    )
  }

  return (
    <div className={cn(styles['th-landing'], 'th-no-header-space')}>
      <div className={styles['th-header-back']} />
      <HomeHero requestDemo={onLearnMore} />
      <section className={cn(styles['th-section'], 'py-20 pb-10')}>
        <div className='flex flex-col items-center'>
          <h3 className='font-heading mb-3 text-center text-3xl font-semibold leading-none tracking-wide md:text-3xl xl:text-4xl'>
            What is TopHap?
          </h3>
          <h3 className='text-md font-heading md:text-md xl:text-md mb-10 text-center font-semibold leading-none tracking-wide'>
            Top Homes And Properties
          </h3>
          <ul className='text-md px-6 text-left font-thin text-black  lg:w-3/4 lg:px-0 lg:text-xl'>
            <li className='mb-6'>
              <div className='flex items-start justify-start sm:items-center'>
                <div className='mr-4 flex h-[40px] min-h-[40px] w-[40px] min-w-[40px] items-center justify-center rounded-xl bg-header text-white'>
                  <SvgMap className='h-6 w-6 text-white' />
                </div>
                <span>
                  Technology platform for geospatial data
                  visualization and analysis with a focus on residential
                  and commercial real estate.
                </span>
              </div>
            </li>
            <li className='mb-6'>
              <div className='flex items-start justify-start sm:items-center'>
                <div className='mr-4 flex h-[40px] min-h-[40px] w-[40px] min-w-[40px] items-center justify-center rounded-xl bg-header text-white'>
                  <SvgData className='h-6 w-6 text-white' />
                </div>
                <span>
                  Vast library of 47 trillion records, covering 150 million
                  parcels across the United States.
                </span>
              </div>
            </li>
            <li>
              <div className='flex items-start justify-start sm:items-center'>
                <div className='mr-4 flex h-[40px] min-h-[40px] w-[40px] min-w-[40px] items-center justify-center rounded-xl bg-header text-white'>
                  <SvgTrends className='h-6 w-6 text-white' />
                </div>
                <span>
                  Innovative visualization tools, comprehensive data, and
                  industry-specific solutions for individuals and enterprises.
                </span>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section
        className={cn(styles['th-section'], styles['th-main-features-section'])}
      >
        <h3 className='font-heading mb-10 text-center text-3xl font-semibold leading-none tracking-wide md:text-3xl xl:text-4xl'>
          Our Products
        </h3>
        <div className={cn('container', styles['th-section-content'])}>
          <div className='mx-auto grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3'>
            {renderFeatureBlock({
              imgAlt: 'TopHap studio',
              imgName: 'tophap-studio',
              title: (
                <span className='font-bold'>Analytics Studio</span>
              ),
              description:
                'Real Estate analytics made simple',
              subDescription:
                ['Powerful analytics and map layers enable you to uncover insights about regions, neighborhoods, properties and lots in seconds.'],
              action: (
                <Link className='mb-10 inline-block rounded-full bg-primary px-12 py-4 text-sm leading-none text-white shadow hover:bg-indigo-700' href='/products/tophap-studio'>Try Now</Link>
              )
            })}
            {renderFeatureBlock({
              imgAlt: 'TopHap Data Fusion',
              imgName: 'tophap-ai',
              tagLineClass: 'md:mb-[44px]',
              title: (
                <span className='font-bold'>TopHap AI</span>
              ),
              description:
                'Real Estate AI Chatbot platform',
              subDescription: [
                'Chat-Based inferface',
                'Nationwide coverage',
                'Whitelabel ready and integrated with your data'
              ],
              action: (
                <Link className='mb-10 inline-block rounded-full bg-primary px-12 py-4 text-sm leading-none text-white shadow hover:bg-indigo-700' href='https://www.tophap.ai' target='_blank'>Try Now</Link>
              )
            })}
            {renderFeatureBlock({
              imgAlt: 'TopHap Explorer',
              imgName: 'tophap-explorer',
              title: (
                <strong>TopHap Explorer</strong>
              ),
              description:
                'Mobile first neighborhood exploration',
              subDescription:
                ['Access to all TopHap data', 'See neighborhood analytics', 'Identify Environmental Hazards', 'Review 100+ data points'],
              action: (
                <a
                  className={cn(styles['th-academy-button'], 'justify-start justify-items-start mb-10')}
                  href='https://apps.apple.com/us/app/tophap-explorer/id1551845829'
                  rel='noreferrer'
                  target='_blank'
                >
                  <SVGAppStoreButton />
                </a>
              )
            })}
            {renderFeatureBlock({
              imgAlt: 'TopHap Embedded Experience',
              imgName: 'tophap-embedded',
              title: (
                <span className='font-bold'>Embedded Experience</span>
              ),
              description:
                'Embed TopHap elements into your product (Web or Mobile)',
              subDescription:
                ['UI Widgets', 'Interactive Maps', 'Visual Heat-maps', 'Map Layers'],
              action: (
                <button className='mb-10 inline-block rounded-full bg-primary px-12 py-4 text-sm leading-none text-white shadow hover:bg-indigo-700' onClick={() => onLearnMore('Embedded-experience')}>Request Demo</button>
              )
            })}
            {renderFeatureBlock({
              imgAlt: 'TopHap API',
              imgName: 'tophap-api',
              title: (
                <span className='font-bold'>TopHap API</span>
              ),
              description:
                'Accelerate your growth and go-to-market',
              subDescription:
                ['Simplify your development process with TopHap\'s property and geospatial data API.'],
              action: (
                <button className='mb-10 inline-block rounded-full bg-primary px-12 py-4 text-sm leading-none text-white shadow hover:bg-indigo-700' onClick={() => onLearnMore('API')}>Request Demo</button>
              )
            })}
            {renderFeatureBlock({
              imgAlt: 'TopHap Data Fusion',
              imgName: 'tophap-data-fusion',
              tagLineClass: 'md:mb-[44px]',
              title: (
                <span className='font-bold'>Data Fusion</span>
              ),
              description:
                'Unlock your data\'s full potential',
              subDescription: [
                'Bring your own data into our platform',
                'Add real estate context to your data',
                'Visualize and Analyze your data',
                'Derive insights from your data'
              ],
              action: (
                <button className='mb-10 inline-block rounded-full bg-primary px-12 py-4 text-sm leading-none text-white shadow hover:bg-indigo-700' onClick={() => onLearnMore('Data Fusion')}>Request Demo</button>
              )
            })}
          </div>
        </div>
      </section>

      <section
        className={cn(styles['th-section'], styles['th-analytics-section'])}
      >
        <h3 className='font-heading mb-3 text-center text-3xl font-semibold leading-none tracking-wide md:text-3xl xl:text-4xl'>
          Our Data
        </h3>
        <p className='text-md mb-10 px-6 text-center font-thin text-black  lg:w-1/2 lg:px-0 lg:text-xl'>
          Access our 47 trillion record library, spanning 150 million United States parcels.
        </p>
        <div className={cn('container', styles['th-analytics-metrics'])}>
          {categories.map(category => (
            <div key={category.id} className={styles['th-category']}>
              <div
                className={styles['th-category-title']}
                style={{ color: category.color }}
              >
                <category.Icon />
                {' '}
                {category.title}
              </div>
              <div className={styles['th-metrics']}>
                {category.items.map((e: TopHap.AnalyticsMetric) => (
                  <div
                    key={e}
                    className={styles['th-metric']}
                    onClick={() => onAnalyticsMetric(e)}
                  >
                    <Tooltip
                      tooltip={analyticsData[e].tooltip}
                      trigger='hover'
                      wrap={false}
                    >
                      <span>{analyticsData[e].title}</span>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>

      <section
        className={cn(
          styles['th-section'],
          styles['th-inman-testimonial-section']
        )}
      >
        <div className={cn('container', styles['th-section-content'])}>
          <div className={styles['th-testimonial-text']}>
            <p>
              When a user browses TopHap’s tools, they’ll quickly understand how
              even a single street is ultimately its own micro market, pulling
              the curtain back on everything that makes real estate worth what
              it’s worth. My take is that there is no better way to understand a
              market than by understanding how it relates to the markets around
              it.
            </p>
            <p>
              After all, markets touch, and like the properties of thermal
              conduction, values spread through direct contact, and TopHap puts
              you in the middle of all that market energy.
            </p>
          </div>
          <a
            className={styles['th-author']}
            href='https://www.inman.com/author/craig-rowe/'
            rel='noopener noreferrer'
            target='_blank'
          >
            - Craig Rowe
          </a>
          <a
            className={styles['th-logo']}
            href='https://www.inman.com/2019/11/08/map-based-data-service-tophap-helps-agents-master-their-market/'
            rel='noopener noreferrer'
            target='_blank'
          >
            <Image
              alt='inman'
              layout='responsive'
              src={imgInmanLogo}
            />
          </a>
        </div>
      </section>

      <section
        className={cn(styles['th-section'], styles['th-sitemap-section'])}
      >
        <div className={cn('container', styles['th-section-content'])}>
          <h3 className={styles['th-section-title']}>
            Browse Properties by State
            <Link href='/sitemap/usa'>View full list</Link>
          </h3>
          <div className={styles['th-links']}>
            <ul className={cn({ [styles['th-show-less']]: !allLinks })}>
              {sitemapLinks.map(e => (
                <li key={e.href}>
                  <Link href={e.href}>{e.title}</Link>
                </li>
              ))}
            </ul>
            <div className={styles['th-show-links']} onClick={toggleAllLinks}>
              {allLinks ? 'Show Less' : 'Show More'}
            </div>
          </div>
        </div>
      </section>
      <CookieConsent />
    </div>
  )
}

export default Home
